import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Format</AnchorLink>
      <AnchorLink mdxType="AnchorLink">States</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Inline loading`}</em>{` spinners are used when performing actions. They help notify user’s that their action is being processed.
The waiting experience is a crucial design opportunity. Although it may not be obvious what is occurring on the back-end, we can communicate clearly to reassure the user that progress is happening.`}</p>
    <p>{`It is best practice to use an Inline loading component for any Create, Update, or Delete actions. The component provides feedback to the user about the progress of the action they took. This could be in a table, after a primary or secondary button click, or even in a modal.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If the inline loading is being used to submit a form, the form fields should get disabled.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Descriptive text for the action is optional, but encouraged. If you have limited room or find adding text to be unnecessary you can just run through the animations.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<em parentName="p">{`success`}</em>{` state of the inline loading component is active for 1.5 seconds before calling a optional function onSuccess. Use the onSuccess function to refetch new data, close a modal, or reset a form. If no function is specified the success state will last indefinitely.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If an error occurs, the Inline loading component should be hidden and a error notification should be added or error handling within a form should appear.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The inline loading component should never be used to load a page or data.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "states"
    }}>{`States`}</h2>
    <h4 {...{
      "id": "loading"
    }}>{`Loading`}</h4>
    <p>{`The `}<em parentName="p">{`loading`}</em>{` state indicates that the action is still in progress.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAdklEQVQY02NgwAG82p/D2VEpRQzBgX4MMTExYH5YWBgDScC6+SuYtm18x/j//3+4uKurK0NsbCwDyaBsIcR1Fcs+CFe2TBfy83LmjYyK5oC5jmQXrtl3E0zvufCRsbi0iikwwJ8pIiKCkWwDQWDXhS8YYsQYBAB0kyWffFIa8wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of inline loading",
            "title": "Example of inline loading",
            "src": "/static/6e7d4ff113621ee4b6b5158a109d9fe9/fb070/inline-loading-usage-1.png",
            "srcSet": ["/static/6e7d4ff113621ee4b6b5158a109d9fe9/d6747/inline-loading-usage-1.png 288w", "/static/6e7d4ff113621ee4b6b5158a109d9fe9/09548/inline-loading-usage-1.png 576w", "/static/6e7d4ff113621ee4b6b5158a109d9fe9/fb070/inline-loading-usage-1.png 1152w", "/static/6e7d4ff113621ee4b6b5158a109d9fe9/c3e47/inline-loading-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "success"
    }}>{`Success`}</h4>
    <p>{`The `}<em parentName="p">{`success`}</em>{` state indicates that the action completed successfully.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAYUlEQVQY02NgwAG8IrLBdFSoH0NoWDiYHRoaykAR2OSFYIeGhpFvqGD8HTDNkviN1Tc8nTsiLIgX6EpmkFhYWBjpBgrF34Ywkv4zBIQnM4WHBTOFQb1NloEQw/5hCBFjGAB0Axb/f1V8UQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of loading success",
            "title": "Example of loading success",
            "src": "/static/ceeb046c5d841aba9a4793a0adb9e835/fb070/inline-loading-usage-2.png",
            "srcSet": ["/static/ceeb046c5d841aba9a4793a0adb9e835/d6747/inline-loading-usage-2.png 288w", "/static/ceeb046c5d841aba9a4793a0adb9e835/09548/inline-loading-usage-2.png 576w", "/static/ceeb046c5d841aba9a4793a0adb9e835/fb070/inline-loading-usage-2.png 1152w", "/static/ceeb046c5d841aba9a4793a0adb9e835/c3e47/inline-loading-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "error"
    }}>{`Error`}</h4>
    <p>{`The `}<em parentName="p">{`error`}</em>{` state indicates that the action did not complete successfully.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAaUlEQVQY02NgwAGOqetDGHXlDGn+AQwh4eFgblhYGEM4lE00uCWnAaZPq+iiiIMMIwucUtUD09flNTkKvXx4/CMjuMPDwjhghpJs8H4tIzD9REqVISUgkCk4PJwJaAgj2QbCDEMHxBgEAE/gGkOW9Xx1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of loading error",
            "title": "Example of loading error",
            "src": "/static/542be6839826eadf50e11925c2385445/fb070/inline-loading-usage-3.png",
            "srcSet": ["/static/542be6839826eadf50e11925c2385445/d6747/inline-loading-usage-3.png 288w", "/static/542be6839826eadf50e11925c2385445/09548/inline-loading-usage-3.png 576w", "/static/542be6839826eadf50e11925c2385445/fb070/inline-loading-usage-3.png 1152w", "/static/542be6839826eadf50e11925c2385445/c3e47/inline-loading-usage-3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      